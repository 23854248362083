const tags = {
    "HTML":"rgba(239,223,26,0.85)",
    "Firebase":"rgba(252,207,38,0.85)",
    "TensorFlow":"rgba(255,165,0,0.85)",
    "PyTorch":"rgba(255,136,0,0.85)",
    "AWS":"rgba(255, 153, 0, 0.85)",
    "Rust":"rgba(183,65,14, 0.85)",
    "Java":"rgba(255,0,0,0.85)",
    "p5JS":"rgba(250,32,85,0.85)",
    "Raspberry Pi":"rgba(232,72,101,0.85)",
    "MacOS":"rgba(232,72,176,0.85)",
    "Swift":"rgba(139,7,183,0.85)",
    "SwiftUI":"rgba(57,7,183,0.85)",
    "C++":"rgba(4,79,136,0.85)",
    "PostgreSQL":"rgba(0,117,203,0.85)",
    "Godot":"rgba(50,152,227,0.85)",
    "CSS":"rgba(19,185,245,0.85)",
    "ReactJS":"rgba(32,227,227,0.85)",
    "React Native":"rgba(0,189,189,0.85)",
    "SocketIO":"rgb(107,190,160,0.85)",
    "Python":"rgba(91,245,91,0.85)",
    "GLSL":"rgba(53,190,11,0.85)",
    "NodeJS":"rgba(15,122,16,0.85)",
    "Flask":"rgba(8,87,39,0.85)",
    "iOS":"rgba(18,51,20,0.85)",
    "MacOS Driver":"rgba(4,62,39,0.85)",
    "OpenAI":"rgba(1,19,28,0.85)",
}

export const displayedTags = [
    "AWS",
    "Java",
    "Raspberry Pi",
    "MacOS",
    "Swift",
    "C++",
    "PostgreSQL",
    "Godot",
    "ReactJS",
    "React Native",
    "Python",
    "NodeJS",
    "Flask",
    "iOS",
]

export default tags;
