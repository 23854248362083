import React, {useState} from 'react';
import '../../../App.css';
import './Home.css'
import Project from '../Project'
import SearchBar from '../SearchBar'
import {projects} from "../ProjectList";
import GraphBackground from "../../backgrounds/GraphBackground";


function Overview() {

    let wrapperKey = 0;

    let [includedProjects, setIncludedProjects] = useState(
        Object.entries(projects).map(function ([key, value]) {
            return (<div key={wrapperKey++} className={"project-wrapper"}><Project title={key} date={value['date']} time={value['time']} link={value['link']} description={value['description']} image={value['image']} tags={value['tags']}/></div>);
        })
    );

    function updatedSelection(tags){
        let objects = [];
        Object.entries(projects).map(function ([key, value]) {
            if (Object.keys(tags).every(tag => (value['tags'].includes(tag) || !tags[tag]))) {
                objects.push(<div key={wrapperKey++} className={"project-wrapper"}><Project title={key} date={value['date']} time={value['time']} link={value['link']} description={value['description']} image={value['image']} tags={value['tags']}/></div>);
            }else{
                objects.push(<div key={wrapperKey++} className={"project-wrapper project-wrapper-hidden"}></div>);
            }
            return objects
        });

        setIncludedProjects(objects);
    }

    function addProjectsWithTags() {
        if(includedProjects.length === 0){
            return <h2>No results found</h2>
        }
       return includedProjects;
    }

    return (
        <>
            <div className="overviewHeader">
                <GraphBackground suggestedBrightness={1}/>
                <h1>Projects</h1>
            </div>
            <div className="overview">
                <SearchBar updatedSelection={updatedSelection}/>
                <br/>
                <div className="project-list-wrapper">
                    {addProjectsWithTags()}
                </div>
                <br/>
            </div>
        </>
    );
}

export default Overview;
